import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swip from "../components/swiper";
import useFetch from "../hooks/usefetch";

import YoutubeThumnail from "../components/youtube-thumnail";
import NewsTitle from "../components/reportFront";
import MultiMediaLayout from "../layouts/MultiMedia";
import AutherAndDate from "../components/dateandauther";
import YoutubeVideo from "../components/youtubevideo";
import { Helmet } from "react-helmet";
import Loading from "../components/lodaing/loading";

import LastNews from "../layouts/lastnews";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Home = () => {
  const categoryUrl = "https://api.kbclive.net/category/";
  const lastfiveroute = "https://api.kbclive.net/last-five-for-home-page";
  const lastarticleroute = "https://api.kbclive.net/last-ten-articles";
  const lastreportroute = "https://api.kbclive.net/last-report-for-home-page";
  const lastinterview = "https://api.kbclive.net/last-interview";

  const { getData: getCategory, data: category, isloading } = useFetch();
  const { getData: getLastFive, data: lastFive } = useFetch();
  const { getData: getLastArticle, data: lastArticle } = useFetch();
  const { getData: getLastReport, data: lastReport } = useFetch();
  const { getData: getLastInterview, data: interview } = useFetch();
  useEffect(() => {
    getCategory(categoryUrl);
    getLastFive(lastfiveroute);
    getLastArticle(lastarticleroute);
    getLastReport(lastreportroute);
    getLastInterview(lastinterview);
  }, []);

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className=" relative">
      <Helmet>
        <title>پەرەی سەرەکی کەناڵی KBC</title>
      </Helmet>

      <header className=" container px-5 sm:px-0 ">
        <Swip news={lastFive} />
      </header>

      <div className=" hidden container py-10 px-5 grid-cols-3 gap-10 md:grid-cols-2 xs:grid-cols-1 xs:py-5 xs:gap-5 sm:px-2 ">
        {lastFive &&
          lastFive?.slice(3, 7).map((news) => (
            <div key={news.news_id}>
              <div className=" grid grid-cols-2 shadow-md gap-3 bg-green  overflow-hidden ">
                <div className="p-4 ">
                  <span className=" font-calib-l  mb-1 block text-white">
                    {news?.news_date?.substring(0, 10)}
                  </span>
                  <Link
                    to={`/details/${news?.news_id}`}
                    className="  text-white  font-calib-b  text-2xl xs:text-xl"
                  >
                    {news?.news_title.length > 50
                      ? `${news?.news_title.slice(0, 50)}...`
                      : news?.news_title}
                  </Link>
                </div>
                <div className="p-2">
                  <LazyLoadImage
                    className=" h-48 object-cover xs:h-36"
                    src={`https://api.kbclive.net/uploads/${news?.image}`}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>

      <LastNews />

      <div className="py-10 mb-10 container px-5 sm:px-2">
        <div
          style={{ whiteSpace: "nowrap" }}
          className="flex items-center gap-3 mb-5"
        >
          <h1 className="text-2xl text-blue-500 font-bold font-calib-b">
            ڕاپۆرتە هەواڵ
          </h1>
          <div className=" bg-blue-500 w-full h-[3px]"></div>
        </div>
        <div className=" grid grid-cols-3 gap-5 md:grid-cols-2 xs:grid-cols-1">
          {lastReport &&
            lastReport.map((program) => (
              <div key={program.news_id} className=" p-2 bg-blue-500 ">
                <div className=" text-white">
                  <div>
                    <LazyLoadImage
                      src={`https://api.kbclive.net/uploads/${program?.image}`}
                      alt=""
                      effect="blur"
                      placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                    />
                  </div>

                  <div className=" px-4 py-2 flex flex-col">
                    {program?.category_id === 4 && (
                      <h1 className=" text-lg   font-calib-r ">
                        {program?.program_kind}
                      </h1>
                    )}
                    <div className=" -mt-[4px] text-white font-calib-b mb-3">
                      <AutherAndDate news={program} />
                    </div>
                    <Link
                      to={`/details/${program.news_id}`}
                      className="text-2xl font-calib-b  hover:text-gray-300 sm:text-xl"
                    >
                      {program.news_title}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <MultiMediaLayout />
      <div className="py-10 max-w-5xl mx-auto px-14 md:px-5 xs:px-2 ">
        <div className=" flex items-center gap-3 mb-5 ">
          <h1 className=" text-2xl font-calib-b ">چاوپێکەوتن</h1>
          <div className=" bg-green w-full h-[3px]"></div>
        </div>

        <div>
          <div className="mb-6 p-2 bg-gray-100">
            <YoutubeVideo
              videoUrl={interview[0]?.news_link.substring(
                interview[0]?.news_link.lastIndexOf("/") + 1
              )}
            />
          </div>
        </div>
        <div className=" grid grid-cols-2  gap-5 sm:grid-cols-1 sm:gap-3  ">
          {interview?.slice(1).map((interview) => (
            <div key={interview.news_id} className=" p-2  bg-gray-100">
              <div className="">
                <div>
                  <img
                    src={`https://api.kbclive.net/uploads/${interview?.image}`}
                    alt=""
                  />
                </div>

                <div className=" px-4 py-2 flex flex-col">
                  {interview?.category_id === 4 && (
                    <h1 className=" text-lg   font-calib-r ">
                      {interview?.interview_kind}
                    </h1>
                  )}
                  <div className=" -mt-[4px] text-green font-calib-b mb-3">
                    <AutherAndDate news={interview} />
                  </div>
                  <Link
                    to={`/details/${interview.news_id}`}
                    className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                  >
                    {interview.news_title}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
