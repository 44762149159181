import { useState, useEffect } from "react";
import useFetch from "../hooks/usefetch";
import { BsPlayFill } from "react-icons/bs";
import documentbg from "../images/document-bg.jpg";
import LastNews from "../layouts/lastnews";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MultiMediaLayout from "../layouts/MultiMedia";
import YoutubeThumnail from "../components/youtube-thumnail";
import YoutubeVideo from "../components/youtubevideo";
import ArticleLayout from "../layouts/articlelaout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AutherAndDate from "../components/dateandauther";
import Loading from "../components/lodaing/loading";
const Document = () => {
  const newsUrl = "https://api.kbclive.net/category/8";

  const [visibledocuments, setVisibledocuments] = useState(12);
  const { getData: getdocument, data: document, isloading } = useFetch();

  useEffect(() => {
    getdocument(newsUrl);
  }, []);

  const handleLoadMore = () => {
    setVisibledocuments((prevCount) => prevCount + 8);
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Helmet>
        <title>دۆکیۆمێنتاری</title>
      </Helmet>
      <div
        style={{
          background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${documentbg})`,
          backgroundSize: "cover",
        }}
      >
        <div className=" py-10 max-w-6xl mx-auto px-5 sm:px-3  ">
          <h1 className="text-5xl my-10  text-center font-calib-b ">
            دۆکیۆمێنتاری
          </h1>
          <div className=" grid grid-cols-2 gap-5 mb-5  md:grid-cols-1">
            {document?.slice(0, 2).map((document) => (
              <div key={document?.news_id} className=" p-2  bg-gray-100">
                <div className=" hover:text-green">
                  <Link to={`/details/${document.news_id}`}>
                    <LazyLoadImage
                      src={`https://api.kbclive.net/uploads/${document?.image}`}
                      alt=""
                      effect="blur"
                      placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                    />
                  </Link>

                  <div className=" px-4 py-2 flex flex-col">
                    <div className=" -mt-[4px] text-green font-calib-b mb-3">
                      <AutherAndDate news={document} />
                    </div>
                    <Link
                      to={`/details/${document.news_id}`}
                      className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                    >
                      {document?.news_title}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {document[2] && (
            <div className="mb-6">
              <YoutubeVideo
                videoUrl={document[2]?.news_link.substring(
                  document[2]?.news_link.lastIndexOf("/") + 1
                )}
              />
            </div>
          )}

          <div className=" grid grid-cols-2  gap-5 md:grid-cols-2 xs:grid-cols-1 ">
            {document?.slice(3, visibledocuments).map((document) => (
              <div key={document?.news_id} className=" p-2  bg-gray-100">
                <div className=" hover:text-green">
                  <Link to={`/details/${document.news_id}`}>
                    <LazyLoadImage
                      src={`https://api.kbclive.net/uploads/${document?.image}`}
                      alt=""
                      effect="blur"
                      placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                    />
                  </Link>

                  <div className=" px-4 py-2 flex flex-col">
                    <div className=" -mt-[4px] text-green font-calib-b mb-3">
                      <AutherAndDate news={document} />
                    </div>
                    <Link
                      to={`/details/${document.news_id}`}
                      className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                    >
                      {document?.news_title}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {visibledocuments < document.length && ( // show the "Load More" button if there are more items to show
            <div className="flex justify-center mt-6">
              <button
                className="px-5 py-1 font-calib-b  text-xl  text-black border-2 border-white hover:bg-black hover:text-white"
                onClick={handleLoadMore}
              >
                زیاتر ببــــینە
              </button>
            </div>
          )}
        </div>
      </div>
      <div className=" bg-gray-100">
        <ArticleLayout />
      </div>

      <LastNews />
    </div>
  );
};

export default Document;
