import { useState, useEffect } from "react";
import useFetch from "../hooks/usefetch";
import reportbg from "../images/report.jpg";
import AutherAndDate from "../components/dateandauther";
import LastNews from "../layouts/lastnews";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import YoutubeVideo from "../components/youtubevideo";
import Loading from "../components/lodaing/loading";
const Report = () => {
  const newsUrl = "https://api.kbclive.net/category/2";

  const [visibleReports, setVisibleReports] = useState(8);
  const { getData: getReport, data: report, isloading } = useFetch();

  useEffect(() => {
    getReport(newsUrl);
  }, []);

  const handleLoadMore = () => {
    setVisibleReports((prevCount) => prevCount + 8);
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Helmet>
        <title>ڕاپۆرت</title>
      </Helmet>
      <div
        style={{
          background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${reportbg})`,
          backgroundSize: "cover",
          height: "50vh",
        }}
        className=""
      ></div>
      <div className=" py-10 max-w-6xl mx-auto px-5 sm:px-3 -mt-96 gap-8 flex flex-col">
        <div>
          <h1 className="text-6xl mb-2  text-white text-center font-calib-b ">
            ڕاپۆرت
          </h1>
        </div>
        <div>
          <div className="mb-6 p-2 bg-gray-100">
            <YoutubeVideo
              videoUrl={report[0]?.news_link.substring(
                report[0]?.news_link.lastIndexOf("/") + 1
              )}
            />
          </div>
        </div>
        <div className=" grid grid-cols-2  gap-5 sm:grid-cols-1 sm:gap-3  ">
          {report &&
            report.slice(1, visibleReports).map((item) => (
              <div key={item.news_id} className=" p-2 bg-blue-500 ">
                <div className=" text-white">
                  <div>
                    <LazyLoadImage
                      src={`https://api.kbclive.net/uploads/${item?.image}`}
                      alt=""
                      effect="blur"
                      placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                    />
                  </div>

                  <div className=" px-4 py-2 flex flex-col">
                    <div className=" -mt-[4px] text-white font-calib-b mb-3">
                      <AutherAndDate news={item} />
                    </div>
                    <Link
                      to={`/details/${item.news_id}`}
                      className="text-2xl font-calib-b  hover:text-gray-300 sm:text-xl"
                    >
                      {item.news_title}
                    </Link>
                  </div>
                </div>
              </div>
            ))}

          {visibleReports < report.length && (
            <div className="text-center">
              <button
                className="px-5 py-1 font-calib-b  text-xl  text-black border-2 border-green hover:bg-green hover:text-white"
                onClick={handleLoadMore}
              >
                زیاتر ببــــینە
              </button>
            </div>
          )}
        </div>
      </div>
      <LastNews />
    </div>
  );
};

export default Report;
