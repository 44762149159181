import { useState, useEffect } from "react";
import useFetch from "../hooks/usefetch";
import { BsPlayFill } from "react-icons/bs";

import LastNews from "../layouts/lastnews";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MultiMediaLayout from "../layouts/MultiMedia";
import YoutubeThumnail from "../components/youtube-thumnail";
import YoutubeVideo from "../components/youtubevideo";
import AutherAndDate from "../components/dateandauther";
import Loading from "../components/lodaing/loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
const InterView = () => {
  const newsUrl = "https://api.kbclive.net/category/5";

  const [visibleinterviews, setVisibleinterviews] = useState(8);
  const { getData: getinterview, data: interview, isloading } = useFetch();

  useEffect(() => {
    getinterview(newsUrl);
  }, []);

  const handleLoadMore = () => {
    setVisibleinterviews((prevCount) => prevCount + 8);
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Helmet>
        <title>چاوپێکەوتن</title>
      </Helmet>

      <div className=" py-10 max-w-6xl mx-auto px-5 sm:px-3 ">
        <h1 className="text-5xl my-10  text-center font-calib-b ">
          چاوپێکەوتن
        </h1>
        <div>
          <div className="mb-6 p-2 bg-gray-100">
            <YoutubeVideo
              videoUrl={interview[0]?.news_link.substring(
                interview[0]?.news_link.lastIndexOf("/") + 1
              )}
            />
          </div>
        </div>

        <div className=" grid grid-cols-2  gap-5 sm:grid-cols-1 sm:gap-3  ">
          {interview?.slice(1, visibleinterviews).map((interview) => (
            <div key={interview.news_id} className=" p-2  bg-gray-100">
              <div className=" hover:text-green">
                <Link to={`/details/${interview.news_id}`}>
                  <LazyLoadImage
                    src={`https://api.kbclive.net/uploads/${interview?.image}`}
                    alt=""
                    effect="blur"
                    placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                  />
                </Link>

                <div className=" px-4 py-2 flex flex-col">
                  {interview?.category_id === 4 && (
                    <h1 className=" text-lg   font-calib-r ">
                      {interview?.interview_kind}
                    </h1>
                  )}
                  <div className=" -mt-[4px] text-green font-calib-b mb-3">
                    <AutherAndDate news={interview} />
                  </div>
                  <Link
                    to={`/details/${interview.news_id}`}
                    className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                  >
                    {interview.news_title}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        {visibleinterviews < interview.length && ( // show the "Load More" button if there are more items to show
          <div className="flex justify-center mt-6">
            <button
              className="px-5 py-1 font-calib-b  text-xl  text-black border-2 border-green hover:bg-green hover:text-white"
              onClick={handleLoadMore}
            >
              زیاتر ببــــینە
            </button>
          </div>
        )}
      </div>
      <MultiMediaLayout />

      <LastNews />
    </div>
  );
};

export default InterView;
