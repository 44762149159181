import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaSnapchat,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

const Social = () => {
  return (
    <div className="  z-20 top-80 fixed left-0 py-5 bg-black border-l-2 border-green md:hidden ">
      <div className="flex flex-col py-1 px-2 gap-2">
        <Link
          to="https://www.facebook.com/profile.php?id=100090683863659"
          target="_blank"
          className=" duration-300 bg-white hover:bg-green p-[6px] rounded-full"
        >
          <FaFacebookF className=" duration-300  text-xl text-black  hover:text-white" />
        </Link>
        <Link
          to="https://www.instagram.com/kbc.kurdish/"
          target="_blank"
          className=" duration-300 bg-white hover:bg-green p-[6px] rounded-full"
        >
          <AiFillInstagram className=" duration-300  text-xl text-black  hover:text-white" />
        </Link>
        <Link
          to="https://www.youtube.com/@kbc.kurdish"
          target="_blank"
          className=" duration-300 bg-white hover:bg-green p-[6px] rounded-full"
        >
          <FaYoutube className=" duration-300  text-xl text-black  hover:text-white" />
        </Link>
        <Link
          to="https://www.tiktok.com/@kbc.official?_t=8dUJlOoIQ7S"
          target="_blank"
          className=" duration-300 bg-white hover:bg-green p-[6px] rounded-full"
        >
          <FaTiktok className=" duration-300  text-xl text-black  hover:text-white" />
        </Link>
        <Link
          to="https://t.me/KurdishBroadcasting"
          target="_blank"
          className=" duration-300 bg-white hover:bg-green p-[6px] rounded-full"
        >
          <FaTelegramPlane className=" duration-300  text-xl text-black  hover:text-white" />
        </Link>

        <Link
          to="https://twitter.com/KBC77851177"
          target="_blank"
          className=" duration-300 bg-white hover:bg-green p-[6px] rounded-full"
        >
          <FaTwitter className=" duration-300  text-xl text-black  hover:text-white" />
        </Link>
        <Link
          to="https://t.snapchat.com/RPpzAxpD"
          target="_blank"
          className=" duration-300 bg-white hover:bg-green p-[6px] rounded-full"
        >
          <FaSnapchat className=" duration-300  text-xl text-black  hover:text-white" />
        </Link>
      </div>
    </div>
  );
};

export default Social;
