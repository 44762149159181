import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.svg";
import { FiMenu } from "react-icons/fi";
import { BsX } from "react-icons/bs";
import Social from "./socails";
import { useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
const Navbar = () => {
  const [menuOpen, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleMouseEnter = () => {
    setMenu(true);
  };

  const handleMouseLeave = () => {
    setMenu(false);
  };

  return (
    <div className="">
      <div className="bg-green relative py-5  flex justify-center md:hidden ">
        <Link to="/">
          <img src={logo} alt="kbc_logo" className="w-64" />
        </Link>
        <Social />
      </div>
      <div className="  flex justify-center md:justify-between md:py-2 md:px-2 md:bg-green">
        <button
          onClick={() => setMenu(!menuOpen)}
          className="hidden  md:block text-3xl z-30"
        >
          <i className={menuOpen ? " hidden" : " text-white  block"}>
            <FiMenu />
          </i>
          <i className={!menuOpen ? " hidden" : " text-black block"}>
            <BsX />
          </i>
        </button>
        <nav
          className={
            !menuOpen
              ? "items-center flex justify-center  duration-700  md:flex-col md:absolute  md:items-start pr-5 md:py-20  md:top-0 md:right-0  z-10 md:bg-white md:w-full  md:opacity-0  md:translate-x-full "
              : "items-center flex justify-center  duration-700  md:flex-col md:absolute  md:items-start pr-5  md:py-20  md:top-0 md:right-0  z-10 md:bg-white md:w-full  md:opacity-100 md:translate-x-0"
          }
        >
          <Link
            className={`nav-link ${
              location.pathname === "/report" ? "active" : ""
            }`}
            to="/report"
            onClick={() => setMenu(false)}
          >
            ڕاپۆرتە هەواڵ
          </Link>
          <Link
            className={`nav-link nav-border ${
              location.pathname === "/article" ? "active" : ""
            }`}
            to="/article"
            onClick={() => setMenu(false)}
          >
            وتار
          </Link>
          <Link
            className={`nav-link nav-border ${
              location.pathname === "/multimedia" ? "active" : ""
            }`}
            to="/multimedia"
            onClick={() => setMenu(false)}
          >
            مەڵتی میدیا
          </Link>
          <Link
            className={`nav-link nav-border ${
              location.pathname === "/interview" ? "active" : ""
            }`}
            to="/interview"
            onClick={() => setMenu(false)}
          >
            چاوپێکەوتن
          </Link>
          <Link
            className={`font-calib-b border-b-4 border-b-white text-2xl text-gray-600 mt-2 px-7 pt-2 pb-1  md:px-0 md:pl-16 nav-border relative  ${
              location.pathname === "/program"
                ? "border-b-green text-[#000]"
                : ""
            }`}
            onClick={handleDropdown}
            onMouseEnter={handleDropdown}
            onMouseLeave={handleDropdown}
          >
            <div
              className={`text-gray-600  flex justify-end gap-8 items-center ${
                location.pathname === "/program" ||
                "program-sport" ||
                "program-health" ||
                "program-speak" ||
                "program-insocial"
                  ? " text-[#000]"
                  : ""
              }`}
            >
              <span>بەرنامەکان</span>
              {dropdown ? (
                <AiFillCaretUp className=" z-30 duration-300  text-xl text-black  " />
              ) : (
                <AiFillCaretDown className=" z-30 duration-300  text-xl text-black  " />
              )}
            </div>
            {dropdown && (
              <div className=" absolute top-12 flex text-xl flex-col gap-3  bg-white w-full right-0 px-2  py-1 md:bg-green md:text-white">
                <Link
                  className="px-4 border-b-[1px] border-gray-600 pb-1 w-full hover:text-green md:border-gray-100"
                  to="/program"
                  onClick={() => setMenu(false)}
                >
                  {" "}
                  سەرجەم بەرنامەکان
                </Link>
                <Link
                  className="px-4 border-b-[1px] border-gray-600 pb-1 w-full hover:text-green md:border-gray-100"
                  to="/program-insocial"
                  onClick={() => setMenu(false)}
                >
                  لەسۆشیال میدیاوە
                </Link>
                <Link
                  to="/program-sport"
                  className="px-4 border-b-[1px] border-gray-600 pb-1 w-full hover:text-green md:border-gray-100"
                  onClick={() => setMenu(false)}
                >
                  {" "}
                  شیکاری وەرزشی
                </Link>
                <Link
                  to="/program-health"
                  className="px-4 border-b-[1px] border-gray-600 pb-1 w-full hover:text-green md:border-gray-100"
                  onClick={() => setMenu(false)}
                >
                  {" "}
                  ژیری تەندروستی
                </Link>
                <Link
                  to="/program-discourse"
                  className="px-4 border-b-[1px] border-gray-600 pb-1 w-full hover:text-green md:border-gray-100"
                  onClick={() => setMenu(false)}
                >
                  {" "}
                  گوتار
                </Link>
              </div>
            )}
          </Link>

          <Link
            className={`nav-link nav-border ${
              location.pathname === "/health" ? "active" : ""
            }`}
            to="/health"
            onClick={() => setMenu(false)}
          >
            تەندروستی
          </Link>
          <Link
            className={`nav-link nav-border ${
              location.pathname === "/sport" ? "active" : ""
            }`}
            to="/sport"
            onClick={() => setMenu(false)}
          >
            وەرزش
          </Link>
          <Link
            className={`nav-link nav-border ${
              location.pathname === "/document" ? "active" : ""
            }`}
            to="/documentary"
            onClick={() => setMenu(false)}
          >
            دۆکیۆمێنتاری
          </Link>
        </nav>
        <Link className="hidden  md:block bg-green p-1" to="/">
          <img src={logo} alt="kbc_logo" className="w-28" />
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
