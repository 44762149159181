import { BsPlayFill } from "react-icons/bs";
import { Link } from "react-router-dom";
const YoutubeThumnailForMultimedia = ({ image, link }) => {
  return (
    <div
      style={{
        background: `linear-gradient(
      rgba(0, 0, 0, 0.1), 
      rgba(0, 0, 0, 0.1)
    ), url(${`https://api.kbclive.net/uploads/${image}`})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      className="flex flex-col  bg-cover items-center  justify-center h-full w-full bg-no-repeat"
    >
      <Link
        to={`/details/${link}`}
        className=" mt-16 duration-300 bg-red-500 p-2 rounded-full hover:bg-green"
      >
        <BsPlayFill className="w-10 h-10 text-white sm:h-6 sm:w-6" />
      </Link>
    </div>
  );
};

export default YoutubeThumnailForMultimedia;
