import YouTube from "react-youtube";

const YoutubeVideo = ({ videoUrl }) => {
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      showinfo: 0,
      modestbranding: 0,
    },
  };

  return (
    <div>
      <YouTube
        className=" w-full  h-[500px] xs:h-[270px]"
        videoId={videoUrl}
        opts={opts}
      />
    </div>
  );
};

export default YoutubeVideo;
