import { Link } from "react-router-dom";

import logo from "../images/footerlogo.js.svg";
import {
  FaFacebookF,
  FaSnapchat,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import footer from "../images/footer.png";
import { AiFillInstagram } from "react-icons/ai";

const Footer = () => {
  return (
    <div className="  pt-32 sm:pt-64">
      <div className=" bg-green grid grid-cols-2  py-10 sm:grid-cols-1 sm:py-0 sm:place-items-center">
        <div className=" relative z-10 ">
          <img
            src={footer}
            alt="footer"
            className=" absolute w-72 left-10  -top-36 sm:relative sm:mr-10 xs:w-56 "
          />
        </div>
        <div className=" sm:-mt-36">
          <h2 className="mb-4 text-3xl xs:text-center text-white font-calib-b sm:text-xl">
            پێکەوە نەتەوەیەکی مۆدێرن بنیات دەنێین
          </h2>

          <div className="flex z-40 py-1 px-2 sm:justify-center">
            <Link
              to="https://www.facebook.com/profile.php?id=100090683863659"
              target="_blank"
              className="z-50 ml-3 duration-300 bg-white  p-[6px] rounded-full"
            >
              <FaFacebookF className=" duration-300  text-xl text-green  hover:text-green-6" />
            </Link>
            <Link
              to="https://www.instagram.com/kbc.kurdish/"
              target="_blank"
              className="ml-3 z-50 duration-300 bg-white  p-[6px] rounded-full"
            >
              <AiFillInstagram className=" duration-300  text-xl text-green  hover:text-green-6" />
            </Link>
            <Link
              to="https://www.youtube.com/@kbc.kurdish"
              target="_blank"
              className="ml-3 z-50 duration-300 bg-white  p-[6px] rounded-full"
            >
              <FaYoutube className=" duration-300  text-xl text-green  hover:text-green-6" />
            </Link>
            <Link
              to="https://t.me/KurdishBroadcasting"
              target="_blank"
              className=" ml-3 z-50 duration-300 bg-white  p-[6px] rounded-full"
            >
              <FaTelegramPlane className=" duration-300  text-xl text-green  hover:text-green-6 " />
            </Link>

            <Link
              to="https://www.tiktok.com/@kbc.official?_t=8dUJlOoIQ7S"
              target="_blank"
              className="z-50 ml-3 duration-300 bg-white  p-[6px] rounded-full"
            >
              <FaTiktok className=" duration-300  text-xl text-green  hover:text-green-6" />
            </Link>

            <Link
              to="https://twitter.com/KBC77851177"
              target="_blank"
              className="z-50 ml-3 duration-300 bg-white  p-[6px] rounded-full"
            >
              <FaTwitter className=" duration-300  text-xl text-green  hover:text-green-6" />
            </Link>
            <Link
              to="https://twitter.com/KBC77851177"
              target="_blank"
              className="z-50 ml-3 duration-300 bg-white  p-[6px] rounded-full"
            >
              <FaSnapchat className=" duration-300  text-xl text-green  hover:text-green-6" />
            </Link>
          </div>
        </div>
      </div>
      <div className=" max-w-5xl mx-auto pt-16 xs:px-1">
        <div className=" grid grid-cols-2 border-b-2 border-black pb-10 sm:grid-cols-1  ">
          <div className=" grid grid-cols-2  xs:mx-auto xs:gap-16  ">
            <div className="  justify-start flex flex-col gap-3">
              <Link
                className="text-2xl font-calib-b text-black hover:text-green xs:text-xl "
                to="/report"
              >
                راپۆرت
              </Link>
              <Link
                className="text-2xl font-calib-b text-black hover:text-green xs:text-xl"
                to="/article"
              >
                وتار
              </Link>
              <Link
                className="text-2xl font-calib-b text-black hover:text-green xs:text-xl"
                to="/multimedia"
              >
                مەڵتی میدیا
              </Link>
              <Link
                className="text-2xl font-calib-b text-black hover:text-green xs:text-xl"
                to="/program"
              >
                بەرنامەکان
              </Link>
            </div>

            <div className=" justify-end content-end flex flex-col gap-3">
              <Link
                className="text-2xl font-calib-b text-black hover:text-green xs:text-xl"
                to="/health"
              >
                تەندروستی
              </Link>
              <Link
                className="text-2xl font-calib-b text-black hover:text-green xs:text-xl"
                to="/sport"
              >
                وەرزش
              </Link>
              <Link
                className="text-2xl font-calib-b text-black hover:text-green xs:text-xl"
                to="/documentary"
              >
                دۆکیۆمێنتاری
              </Link>
              <Link
                className="text-2xl font-calib-b text-black hover:text-green xs:text-xl"
                to="/interview"
              >
                چاوپێکەوتن
              </Link>
            </div>
          </div>
          <div className="pr-20 sm:row-start-1 sm:pr-10 sm:pl-10 sm:mb-8 xs:text-xl">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
        <div className=" flex justify-between   py-5 sm:px-5 sm:flex-col sm:justify-center sm:items-center">
          <h1 className="text-2xl font-calib-b  sm:mb-1 sm:text-base">
            مافی بڵاوکردنەوەی پارێزراوە بۆ{"\u00A0"}
            <span className="text-green-3">KBC </span>
          </h1>

          <p className=" text-green-3 font-calib-b text-2xl sm:text-base">
            2023
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
