import { useState } from "react";

const useFetch = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState("");

  const getData = async (url) => {
    try {
      setIsloading(true);
      const res = await fetch(url);
      if (!res.ok) {
        throw new Error("hallayk haya");
      }

      const data = await res.json();
      setData(data);

      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return { getData, data, isloading, error };
};

export default useFetch;
