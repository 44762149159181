import { useState, useEffect } from "react";
import useFetch from "../hooks/usefetch";
import { BsPlayFill } from "react-icons/bs";
import sportbg from "../images/sport-bg.png";
import LastNews from "../layouts/lastnews";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MultiMediaLayout from "../layouts/MultiMedia";
import YoutubeThumnail from "../components/youtube-thumnail";
import YoutubeVideo from "../components/youtubevideo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loading from "../components/lodaing/loading";
const Sport = () => {
  const newsUrl = "https://api.kbclive.net/category/6";

  const [visiblesports, setVisiblesports] = useState(7);
  const { getData: getsport, data: sport, isloading } = useFetch();

  useEffect(() => {
    getsport(newsUrl);
  }, []);

  const handleLoadMore = () => {
    setVisiblesports((prevCount) => prevCount + 8);
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Helmet>
        <title>وەرزش</title>
      </Helmet>

      <div className=" ">
        <div
          style={{
            background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${sportbg})`,
            backgroundSize: "cover",
          }}
          className=" h-64 flex justify-center"
        >
          <h1 className=" text-white mt-16 text-6xl font-calib-b">وەرزش</h1>
        </div>
      </div>
      <div className="-mt-32 ">
        <div className="mb-6  py-10 max-w-6xl mx-auto px-5 sm:px-3   ">
          <div key={sport[0]?.news_id} className=" p-2 bg-gray-100 ">
            <div className="hover:text-green-4">
              <div className=" relative">
                <Link to={`/details/${sport[0]?.news_id}`}>
                  <LazyLoadImage
                    src={`https://api.kbclive.net/uploads/${sport[0]?.image}`}
                    alt=""
                    effect="blur"
                    placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                  />
                </Link>

                <p className=" absolute bottom-1 right-4 w-fit text-white font-calib-b text-3xl mb-2 bg-[#0057FF]  px-3 py-1 xs:text-sm">
                  نوێ
                </p>
              </div>

              <div className=" px-4 py-3 flex flex-col">
                <Link
                  to={`/details/${sport[0]?.news_id}`}
                  className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                >
                  {sport[0]?.news_title}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className=" py-10 max-w-6xl mx-auto px-5 sm:px-3">
          <div className="mb-7  grid grid-cols-2 gap-5 sm:grid-cols-1">
            {sport &&
              sport.slice(1, visiblesports).map((sport) => (
                <div key={sport.news_id} className=" p-2 bg-gray-100 ">
                  <div className="hover:text-green-4">
                    <Link to={`/details/${sport?.news_id}`}>
                      <LazyLoadImage
                        src={`https://api.kbclive.net/uploads/${sport?.image}`}
                        alt=""
                        effect="blur"
                        placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                      />
                    </Link>
                    <div className=" px-4 py-3 flex flex-col">
                      <Link
                        to={`/details/${sport.news_id}`}
                        className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                      >
                        {sport.news_title}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex justify-center">
            {visiblesports < sport.length && (
              <button
                className="px-5 mb-10 py-1  font-calib-b  text-xl  text-black border-2 border-[#0057FF] hover:bg-[#0057FF] hover:text-white"
                onClick={handleLoadMore}
              >
                زیاتر ببــــینە
              </button>
            )}
          </div>
        </div>

        <MultiMediaLayout />

        <LastNews />
      </div>
    </div>
  );
};

export default Sport;
