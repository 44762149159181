import { useState, useEffect } from "react";
import useFetch from "../hooks/usefetch";
import healthbg from "../images/health-bg.jpg";

import LastNews from "../layouts/lastnews";

import { Helmet } from "react-helmet";

import AutherAndDate from "../components/dateandauther";
import { Link } from "react-router-dom";
import ArticleLayout from "../layouts/articlelaout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loading from "../components/lodaing/loading";
const Health = () => {
  const newsUrl = "https://api.kbclive.net/category/7";
  const [visiblehealths, setVisiblehealths] = useState(9);
  const { getData: gethealth, data: health, isloading } = useFetch();

  useEffect(() => {
    gethealth(newsUrl);
  }, []);

  const handleLoadMore = () => {
    setVisiblehealths((prevCount) => prevCount + 8);
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Helmet>
        <title>تەندروستی</title>
      </Helmet>
      <div
        style={{
          background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${healthbg})`,
          backgroundSize: "cover",
        }}
      >
        <div className=" py-10 max-w-6xl mx-auto px-5 sm:px-3">
          <h1 className="text-5xl text-white my-8  text-center font-calib-b  ">
            تەندروستی
          </h1>
          <div key={health[0]?.news_id} className=" mb-6 p-2 bg-gray-100 ">
            <div className="hover:text-green-4">
              <div className=" relative">
                <Link to={`/details/${health[0]?.news_id}`}>
                  <LazyLoadImage
                    src={`https://api.kbclive.net/uploads/${health[0]?.image}`}
                    alt=""
                    effect="blur"
                    placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                  />
                </Link>

                <Link
                  to={`/details/${health[0]?.news_id}`}
                  className=" absolute bottom-1 right-4 w-fit text-white font-calib-b text-3xl mb-2 bg-[#0057FF]  px-3 py-1 xs:text-sm"
                >
                  بخوێنەوە
                </Link>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            {health &&
              health.slice(1, visiblehealths).map((health, index) => (
                <div
                  key={health.news_id}
                  className={`mb-5 border-2  border-[#0057FF] p-2 grid grid-cols-3 md:grid-cols-1 ${
                    index % 2 === 0
                      ? "bg-white text-black"
                      : "bg-[#0057FF] text-white"
                  }`}
                >
                  <Link to={`/details/${health?.news_id}`}>
                    <LazyLoadImage
                      src={`https://api.kbclive.net/uploads/${health?.image}`}
                      alt=""
                      effect="blur"
                      placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                    />
                  </Link>
                  <div className="flex flex-col px-5 pt-5 pb-0 col-start-2 col-end-4 md:col-span-1 md:px-2">
                    <h1 className="mb-1 font-calib-b text-2xl sm:text-xl">
                      {health?.news_title}
                    </h1>
                    <div
                      className="text-lg mb-2 font-calib-r"
                      dangerouslySetInnerHTML={{
                        __html: `${health?.news_description?.slice(
                          0,
                          150
                        )}......`,
                      }}
                    />
                    <Link
                      className={`font-calib-r text-lg px-4 py-1  mb-1  w-fit  ${
                        index % 2 === 0
                          ? "bg-[#0057FF] text-white hover:bg-[#1D2B53]"
                          : " bg-white  text-[#0057FF] hover:bg-[#c9e4ff] "
                      }`}
                      to={`/details/${health?.news_id}`}
                    >
                      زیاتر بخوێنەوە
                    </Link>
                    <div className="mt-auto">
                      <AutherAndDate news={health} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex justify-center">
            {visiblehealths < health.length && (
              <button
                className="px-5 mt-2 py-1  font-calib-b  text-xl  text-black border-2 border-[#0057FF] hover:bg-[#0057FF] hover:text-white"
                onClick={handleLoadMore}
              >
                زیاتر ببــــینە
              </button>
            )}
          </div>
        </div>
        <ArticleLayout />
        <LastNews />
      </div>
    </div>
  );
};

export default Health;
