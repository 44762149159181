import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar";

import Home from "./pages/home";
import Footer from "./components/footer";
import Report from "./pages/report";
import MultiMedia from "./pages/multimedia";
import Article from "./pages/article";
import InterView from "./pages/interview";
import Program from "./pages/program";
import Health from "./pages/health";
import Sport from "./pages/sport";
import Document from "./pages/documents";
import NewsDetails from "./pages/newsdetails";
import SportProgram from "./pages/sport-program";
import HealthProgram from "./pages/health-program";
import SpeakProgram from "./pages/speak-program";
import InScocialProgram from "./pages/insocial-program";
import ScrollToTop from "./components/scrolltotop";

function App() {
  return (
    <div className=" ">
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/report" element={<Report />} />
          <Route path="/multimedia" element={<MultiMedia />} />
          <Route path="/article" element={<Article />} />
          <Route path="/interview" element={<InterView />} />
          <Route path="/program" element={<Program />} />
          <Route path="/program-sport" element={<SportProgram />} />
          <Route path="/program-health" element={<HealthProgram />} />
          <Route path="/program-discourse" element={<SpeakProgram />} />
          <Route path="/program-insocial" element={<InScocialProgram />} />
          <Route path="/health" element={<Health />} />
          <Route path="/sport" element={<Sport />} />
          <Route path="/documentary" element={<Document />} />
          <Route path="/details/:id" element={<NewsDetails />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
