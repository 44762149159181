import { useState, useEffect } from "react";
import useFetch from "../hooks/usefetch";
import AutherAndDate from "../components/dateandauther";
import { Link } from "react-router-dom";
import YoutubeThumnail from "../components/youtube-thumnail";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const LastNews = () => {
  const programroute = "https://api.kbclive.net/last-six-program";

  const { getData: getNews, data: news } = useFetch();

  useEffect(() => {
    getNews(programroute);
  }, []);

  return (
    <div className="max-w-7xl px-5 mx-auto py-10 xs:px-2">
      <div
        style={{ whiteSpace: "nowrap" }}
        className="flex items-center gap-3 mb-5"
      >
        <h1 className="text-2xl text-green font-bold font-calib-b">
          نوێترین بەرنامەکان
        </h1>
        <div className="bg-green w-full h-[3px]"></div>
      </div>
      <div className="grid grid-cols-3 gap-x-5 gap-y-8 md:grid-cols-2 xs:gap-2 xs:grid-cols-1">
        {news &&
          news.map((program) => (
            <div key={program.news_id} className=" p-2 bg-gray-100">
              <div className=" hover:text-green">
                <Link to={`/details/${program.news_id}`}>
                  <LazyLoadImage
                    src={`https://api.kbclive.net/uploads/${program?.image}`}
                    alt=""
                    effect="blur"
                    placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                  />
                </Link>

                <div className=" px-4 py-2 flex flex-col ">
                  {program?.category_id === 4 && (
                    <h1 className=" text-lg   font-calib-r text-black ">
                      {program?.program_kind}
                    </h1>
                  )}
                  <div className=" -mt-[4px] text-green font-calib-b mb-3">
                    <AutherAndDate news={program} />
                  </div>
                  <Link
                    to={`/details/${program.news_id}`}
                    className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                  >
                    {program.news_title}
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="text-center mt-5">
        <Link
          to="/program"
          className="px-5 py-1 font-calib-b  text-xl  text-black border-2 border-green hover:bg-green hover:text-white"
        >
          هەمووی ببینە
        </Link>
      </div>
    </div>
  );
};

export default LastNews;
